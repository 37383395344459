<template>
  <v-container>
    <v-row v-if="!loading">
      <v-col sm="12" md="4">
        <v-text-field :label="crudDisplayName" v-model="name"></v-text-field>
        <v-btn color="blue" dark @click="postData">Add</v-btn>
      </v-col>
      <v-col sm="12" md="8">
        <v-list v-if="dataCollectionLengthChecker">
          <v-list-item v-for="data in dataCollection" :key="data.id">
            <v-list-item-title>
              {{crudDisplayName}}: {{data}}
            </v-list-item-title>
            <v-list-item-action>
              <v-btn text color="primary" @click="$router.push('/' + crud + '/' + data.id)">Select</v-btn>
              <v-btn icon color="red" @click="deleteData(data.id)">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "SimpleCrud",
  props: {
    crud: {
      required: true,
      type: String
    },
    crudDisplayName: {
      required: true,
      type: String
    }
  },
  computed: {
    dataCollectionLengthChecker: function () {
      return this.dataCollection.length > 0
    }
  },
  data() {
    return {
      dataCollection: [],
      name: null,
      loading: false,
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/${this.crud}`, this.$store.state.headers)
          .then(function(response) {
            self.dataCollection = response.data
            self.loading = false
          })
    },
    postData() {
      // let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/${this.crud}`, {
          "title": this.name,
        }, this.$store.state.headers)
            .then(function (response) {
              self.dataCollection.push(response.data);
            })
      });
    },
    deleteData(id) {
      let self = this

      axios.delete(`${this.$store.state.apiUrl}/api/${this.crud}/${id}`, this.$store.state.headers)
          .then(function () {
            self.dataCollection = self.dataCollection.filter(t => t.id !== id)
          })
    }
  },
}
</script>

<style scoped>
</style>
