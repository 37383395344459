<template>
  <SimpleCrud crud="customers" crud-display-name="Customers"></SimpleCrud>
</template>

<script>
import SimpleCrud from "@/components/crud/SimpleCrud";
export default {
  name: "Customers",
  components: {SimpleCrud},
  data: () => ({

  }),

  methods: {

  }
}
</script>

<style scoped>

</style>
